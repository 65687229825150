export enum Status {
  Draft = 1,
  Active = 2,
  Upcoming = 3,
  Expired = 4,
  Deleted = 5,
  'Pending Manager Approval' = 6,
  Suspended = 7,
  Approved = 8,
  Declined = 9,
  'Posted to Portal' = 10,
  Open = 11,
  Closed = 12,
  'Temp Closed' = 13,
  'Coming Soon' = 14,
  'Closing Soon' = 15,
  Pending = 16,
  Modified = 17,
  'Open - Marketing Disabled' = 18,
  'Manually Expired' = 19,
  'Locked Out' = 20,
  Scheduled = 21,
}
