/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpService from '@/services/HttpService';
import { MallState, State } from '@vue/runtime-core';
import { AxiosResponse } from 'axios';
import { ActionContext } from 'vuex';
import { IMall } from '../../../../interfaces/src/v2/index';
import * as Sentry from '@sentry/vue';
import captureExceptionInSentry from './errorHandler';
import { request } from 'http';
import { ContactType } from '@/enums/ContactType';

let errorMessage: string;

interface Response {
  success: boolean;
  data: any;
}

export const MallModule = {
  state(): MallState {
    return {
      malls: [],
      mallChangeRequests: [],
      loadingMalls: false,
      loadingMallChangeRequests: false,
    };
  },

  mutations: {
    clearMalls(state: MallState): void {
      state.malls = [];
    },
    updateMalls(state: MallState, payload: IMall[]): void {
      state.malls = payload;
    },
    updateMallChangeRequests(state: MallState, payload: any[]): void {
      state.mallChangeRequests = payload;
    },
    updateLoadingMalls(state: MallState, payload: boolean): void {
      state.loadingMalls = payload;
    },
    updateLoadingMallChangeRequests(state: MallState, payload: boolean): void {
      state.loadingMallChangeRequests = payload;
    },
  },

  actions: {
    clearMalls(context: ActionContext<MallState, State>): void {
      context.commit('clearMalls');
    },

    async fetchMalls(context: ActionContext<MallState, State>): Promise<any> {
      context.commit('updateLoadingMalls', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMalls(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
        );
        records.data.data.forEach((mall: IMall) => {
          mall.mallOwnerDesc = mall?.mallOwner?.description;
          mall.supportsMarketing = mall.stats.supportsMarketing ? 'Yes' : 'No';
          mall.mallAddress = {
            streetAddress: mall?.streetAddress,
            city: mall?.city,
            province: mall?.province,
            country: mall?.country,
            postalCode: mall?.postalCode,
          };
          mall.mallEditUrl = `${window.origin}/review/mall-details?mallId=${mall?.mallId}`;
          mall.languageList = [];
          if (mall.languages) {
            mall?.languages.forEach((language: any) => {
              mall.languageList!.push(language.description);
            });
          }
          mall.localIsTo = false;
          if (mall.contacts && mall.contacts.length > 0) {
            mall.otherContacts = mall.contacts.map((contact: any) => contact.email);
            // find local contact that is TO
            let localContactIndex = mall.contacts.findIndex((el) => el.contactTypeId === ContactType['Local Contact'] && el.emailType === 'to');
            // find local contact that is CC if no TO
            if (localContactIndex === -1) {
              localContactIndex = mall.contacts.findIndex((el) => el.contactTypeId === ContactType['Local Contact'] && el.emailType === 'cc');
            } else {
              mall.localIsTo = true;
            }
            // remove local contact from remaining contacts if found
            if (localContactIndex > -1) {
              mall.localContact = mall.contacts[localContactIndex];
              mall.otherContacts.splice(localContactIndex, 1);
            }
            mall?.contacts?.forEach((contact: any) => {
              if (contact?.communicationTypes && contact?.communicationTypes?.length > 0) {
                contact.actionRequired = contact?.communicationTypes[0]?.Contact_CommunicationType?.actionRequired === 1 ? true : false;
                contact.notificationOnly = contact?.communicationTypes[0]?.Contact_CommunicationType?.notificationOnly === 1 ? true : false;
              }
            });
          }
          if (mall.communicationTypes) {
            const types: string[] = [];
            mall.communicationTypes?.forEach((type) => {
              types.push(type.description);
            });
            mall.communicationTypeList = types;
          }
        });
        context.commit('updateMalls', records.data.data);
        context.commit('updateLoadingMalls', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingMalls', false);
      }
    },

    async fetchMallChangeRequests(
      context: ActionContext<MallState, State>,
      payload: { page?: number; limit?: number; search?: string; sortKey?: string; sortDirection?: string },
    ): Promise<any> {
      context.commit('updateLoadingMallChangeRequests', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMallChangeRequests(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
        );
        context.commit('updateMallChangeRequests', records.data.data);
        records.data.data.forEach((request: any) => {
          request.requestedBy = {
            email: request?.requestedByEmail,
            firstName: request?.requestedByFirstName,
            lastName: request?.requestedByLastName,
            title: request?.requestedByTitle,
            phone: request?.requestedByPhoneNumber,
          };
          request.actionedBy = request?.actionedByUser?.firstName;
          request.statusDesc = request?.status?.description;
          request.mallName = request?.dataSnapshot?.description;
        });
        context.commit('updateLoadingMallChangeRequests', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingMallChangeRequests', false);
      }
    },

    async fetchMall(context: ActionContext<MallState, State>, payload: { mallId: string }): Promise<any> {
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMallById(payload.mallId);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },

    saveMall(context: ActionContext<MallState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveMall(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchMalls');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },

    updateChangeRequest(context: ActionContext<MallState, State>, payload: { payload: any; requestId: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .updateChangeRequest(payload.payload, payload.requestId)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchMallChangeRequests');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },

    updateMall(context: ActionContext<MallState, State>, payload: { payload: any; mallId: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .updateMall(payload.payload, payload.mallId)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchMalls');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },

    async updateMallRequest(context: ActionContext<MallState, State>, payload: any): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().updateMallRequest(payload);
      return records?.data;
    },

    deleteMall(context: ActionContext<MallState, State>, payload: string): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .deleteMall(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchMalls');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },
  },

  getters: {
    malls(state: MallState): IMall[] {
      return state.malls;
    },
    mallChangeRequests(state: MallState): IMall[] {
      return state.mallChangeRequests;
    },
    loadingMalls(state: MallState): boolean {
      return state.loadingMalls;
    },
    loadingMallChangeRequests(state: MallState): boolean {
      return state.loadingMallChangeRequests;
    },
  },
};
